import PageLayout from "../../components/PageLayout";
import {
    AcademicCapIcon,
    CheckCircleIcon,
    LockClosedIcon,
    PhoneIcon,
    TrophyIcon,
    UsersIcon
} from "@heroicons/react/24/outline";
import {XCircleIcon} from "@heroicons/react/24/solid";
import {useEffect, useState} from "react";
import TelegramButton from "../../components/ui/TelegramButton";
import confetti from 'canvas-confetti';
import {useNavigate} from "react-router-dom";
import AffiliateModal from "./AffiliateModal";
import {Button, Image} from "@nextui-org/react";
import {ItemCounter} from "../../components/icon/ItemCounter";
import useApi from "../../services/useApi";
import AdminSendMessageModal from "./AdminSendMessageModal";




function Admin() {

    const [help, setHelp] = useState(false)

    const {data, loading, fetchData} = useApi()

    useEffect(() => {
        fetchData("/admin/users")
    }, []);

    return (
        <PageLayout
            loading={loading}
            title="Start Page"
            className="bg-wrapper p-8">

            { help && <AdminSendMessageModal setIsPremium={setHelp} onAction={() => setHelp(false)} />}


            <div className="py-4 text-center">
                <UsersIcon className="w-14 h-14 inline-block my-4" />
                <h1 className="text-2xl font-bold">لیست کاربران</h1>
                <button
                    className="text-sm text-blue-400 mt-2"
                    type="button"
                    onClick={() => setHelp(true)}>ارسال پیام به همه</button>
            </div>



            <div className="card flex flex-col space-y-4 p-4">
                {data && data.users && data.users.map((user) => (
                    <div
                        className="flex items-center justify-between">
                        <div className="flex gap-2 items-center">
                            <span className="bg-white bg-opacity-10 rounded-md text-2xl">
                                    <Image
                                        width={42}
                                        height={42}
                                        alt="Profile"
                                        src={user.profile}
                                        fallbackSrc="https://via.placeholder.com/42x42"
                                    />
                            </span>

                            <div>
                                <h3>{user.first_name}</h3>
                                <span className="flex gap-1">
                                    <PhoneIcon className="w-4 h-4 inline-block text-yellow-400" />
                                    <span className="text-xs">{user.mobile}</span>
                                    <span className="text-xs">・{user.username}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

        </PageLayout>
    );
}

export default Admin;
