import PageLayout from "../../components/PageLayout";
import {useEffect} from "react";
import {Link} from "@nextui-org/react";
import { useNavigate} from "react-router-dom";
import {useHapticFeedback} from "@vkruglikov/react-telegram-web-app";
import confetti from "canvas-confetti";

function PaymentResultGuest() {

    const navigate = useNavigate();
    const [impactOccurred] = useHapticFeedback()
    let redirect = (new URLSearchParams(window.location.search)).get("redirect")
    useEffect(() => {


        impactOccurred('light')
        var end = Date.now() + (3 * 1000);
        var colors = ['#bb0000', '#ffffff'];

        (function frame() {
            confetti({
                particleCount: 2,
                angle: 60,
                spread: 55,
                origin: { x: 0 },
                colors: colors
            });
            confetti({
                particleCount: 2,
                angle: 120,
                spread: 55,
                origin: { x: 1 },
                colors: colors
            });

            if (Date.now() < end) {
                requestAnimationFrame(frame);
            }
        }());

    }, []);

    return (
        <div
            className="bg-wrapper-blue p-8">

            <div className="card">
                <div className="text-center text-white p-5">
                <span className="inline-block rounded-full p-2 text-white">
                    <span className="text-6xl">✅</span>
                </span>
                    <h1 className="text-xl font-bold my-2">پرداخت موفق</h1>
                    <p>برای فعال شدن اشتراک ربات تلگرام همین حالا وارد ربات شوید</p>

                    <br/>
                    <a  href={redirect}>{process.env.REACT_APP_BOT_URL}</a>
                    <br/>
                    <Link color="primary" href={redirect}>ورود به ربات</Link>
                </div>
            </div>

        </div>
    );
}

export default PaymentResultGuest;
