import PageLayout from "../../components/PageLayout";
import {
    CurrencyDollarIcon,
} from "@heroicons/react/24/outline";
import React, {useEffect, useState} from "react";
import {Button, Input} from "@nextui-org/react";
import useApi from "../../services/useApi";
import TelegramButton from "../../components/ui/TelegramButton";
import {useNavigate} from "react-router-dom";
import validationSchemas from "../../schema/validationSchemas";
import {useBot} from "../../providers/BotProvider";
import {useAuth} from "../../providers/AuthProvider";



function FormRequest() {

    const navigate = useNavigate()
    const api = useApi()
    const {setMounted} = useBot()
    const {authenticated, user} = useAuth()


    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!selectedFile) {
            setUploadStatus('لطفا عکس متن خواسته شده را بارگزاری نمایید');
            return;
        }

        const formData = new FormData();
        formData.append('image', selectedFile);

        try {
            const response = api.fetchData('form-request', 'POST', formData);

        } catch (error) {
            setUploadStatus(`Error: ${error.message}`);
        }
    };


    useEffect(() => {
        if(api.success){
            setMounted(false)
            navigate("/")
        }
    }, [api.success]);



    return (
        <PageLayout title="Start Page" className=" p-8">

            <div className="text-center p-5">
                <span className="inline-block rounded-full p-2 text-white">
                    <CurrencyDollarIcon className="w-14 h-14" />
                </span>
                <h1 className="text-xl font-bold my-2">درخواست عضویت در دوره سنشی</h1>
                <div className="mb-4 text-right my-4">

                    تعهدنامه زیر رو داخل کاغذ بنویسید پُرش کنید و عکسش رو اینجا اپلود کنید و منتظر تایید صلاحیت باشید:

                    <br/><br/>

                    اینجانب (نام و نام خانوادگی) فرزند (نام پدر) به کد ملی (کد ملی) متولد (تاریخ تولد)، مبلغ دوره را با رضایت کامل برای شرکت در کلاس سنشی پرداخت کرده‌ام.
                    <br/>
                    با امضای این تعهدنامه، بنده تأیید می‌کنم که بالای ۱۸ سال سن دارم و متعهد می‌شوم که تمامی مسئولیت‌ها و اتفاقات مرتبط با کلاس سنشی را پذیرفته و تصدیق می‌کنم که گوینده محتوای سنشی و دست‌اندرکاران دانشگاه سنشی هیچ‌گونه مسئولیتی در قبال بنده و هر گونه اتفاقات تأثیرگذار روی شخص بنده، چه از نظر زندگی مادی و چه از نظر زندگی معنوی، و همچنین اتفاقات تأثیرگذار روی اطرافیان بنده ندارند.
                    <br/>
                    همچنین، هرگونه اقدام آسیب‌زا به خود یا بروز هر نوع افسردگی پس از شرکت در کلاس سنشی بر عهده خود بنده است و دانشگاه سنشی و دست‌اندرکاران آن هیچ‌گونه مسئولیتی در این زمینه نخواهند داشت.
                    <br/>
                    این تعهدنامه با علم و آگاهی کامل امضا شده و هرگونه ادعای خسارت یا درخواست جبران خسارت در آینده توسط بنده از همین لحظه ساقط می‌گردد.
                    <br/><br/>
                    (امضاء)

                </div>
                { (authenticated && user.user_file)?  (
                    <p>لطفا منتظر تایید صلاحیت باشید</p>
                ) : (

                    <div className="flex flex-col gap-3 p-4">
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="">فایل ضمیمه</label>
                            <input type="file" accept="image/*" onChange={handleFileChange} />
                            <Button isLoading={api.loading} disabled={api.success} fullWidth color="primary" className="mt-6" type="submit">ثبت درخواست</Button>
                        </form>
                        {preview && <img src={preview} alt="Preview" className="w-full p-4 rounded-lg mx-auto max-h-60" />}
                        {uploadStatus && <p>{uploadStatus}</p>}
                    </div>
                )}

            </div>

        </PageLayout>
    );
}

export default FormRequest;



