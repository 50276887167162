import React from "react";
import {ChevronRightIcon} from "./ChevronRightIcon";
import {ChevronLeftIcon} from "@heroicons/react/24/outline";
export const ItemCounter = ({number}) => (
    <div className="flex items-center gap-1 text-gray-400">
        <span className="text-small">{number}</span>
        {/*<ChevronRightIcon className="text-xl" />*/}
        <ChevronLeftIcon className="w-6 h-6 inline-block" />
    </div>
);
