import React from "react";
import moment from "moment-timezone";

class Timer2CountDown extends React.Component {
    state = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        miliseconds: 0
    }

    componentDidMount() {
        this.calculateTimeUntil();
        this.myInterval = setInterval(this.calculateTimeUntil, 10);
    }

    componentWillUnmount() {
        clearInterval(this.myInterval);
    }

    calculateTimeUntil = () => {
        const endTime = moment.tz('2024/04/30 20:30', 'Asia/Tehran');
        const now = moment.tz('Asia/Tehran');
        const difference = endTime.diff(now);

        if (difference < 0) {
            clearInterval(this.myInterval);
            this.setState({ days: 0, hours: 0, minutes: 0, seconds: 0, miliseconds: 0 });
        } else {
            const duration = moment.duration(difference);
            const days = Math.floor(duration.asDays());
            const hours = duration.hours();
            const minutes = duration.minutes();
            const seconds = duration.seconds();
            const miliseconds = duration.milliseconds();

            this.setState({ days, hours, minutes, seconds, miliseconds });
        }
    }

    render() {
        const { days, hours, minutes, seconds, miliseconds } = this.state;
        return (
            <>
                <div className="counter">
                    {days === 0 && hours === 0 && minutes === 0 && seconds === 0 && miliseconds === 0
                        ? <h1 className="text-white">شروع شد...</h1>
                        :
                        <div className="time-box-wrapper flex-row-reverse">
                            <div className="single-box">
                                <h1>{days}</h1>
                                <p>روز</p>
                            </div>
                            <div className="single-box">
                                <h1>{hours}</h1>
                                <p>ساعت</p>
                            </div>
                            <div className="single-box">
                                <h1>{minutes}</h1>
                                <p>دقیقه</p>
                            </div>
                            <div className="single-box">
                                <h1>{seconds}</h1>
                                <p>ثانیه</p>
                            </div>
                        </div>
                    }
                </div>
            </>
        );
    }
}

export default Timer2CountDown;
