import {
    Modal,
    ModalContent,
    ModalBody,
    ModalFooter,
    useDisclosure
} from "@nextui-org/react";
import {StarIcon, XCircleIcon} from "@heroicons/react/24/solid";
import {rendition} from "../../services/i18n";
import {useEffect} from "react";
import TelegramButton from "../../components/ui/TelegramButton";
import {useNavigate, useSearchParams} from "react-router-dom";
import {AcademicCapIcon, ArrowTrendingUpIcon, RocketLaunchIcon, WalletIcon} from "@heroicons/react/24/outline";
import Button from "../../components/ui/Button";



function Content() {
    return (
        <>
            <div className="text-center p-5">
                <span className="inline-block rounded-full p-2 text-white">
                    <AcademicCapIcon className="w-14 h-14" />
                </span>
                <h1 className="text-xl font-bold my-2">آزمون</h1>
                <p>برای دسترسی به محتوای آموزشی این بخش لازم است تا در آزمون شرکت کنید و حداقل به 5 سوال پاسخ صحیح دهید</p>
            </div>
        </>
    )
}


const StartQuizModal = ({setIsPremium, onAction}) => {

    const navigate = useNavigate()
    const {isOpen, onOpen} = useDisclosure()
    const [queryParameters] = useSearchParams()
    let bot_id = queryParameters.get("bot_id")

    useEffect(() => {
        onOpen()
    }, []);

    return (
        <Modal
            placement="auto"
            closeButton={<XCircleIcon />}
            classNames={{
                closeButton: "hover:bg-white/5 active:bg-white/10 inline-block w-10 h-10 m-2",
            }}
            isOpen={isOpen}
            backdrop="blur"
            isDismissable={false}
            onClose={() => setIsPremium(false)}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalBody>
                            <Content />
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                fullWidth onClick={onAction} >بزن بریم</Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    )
}

export default StartQuizModal