import {useAuth} from "../../providers/AuthProvider";
import PageLayout from "../../components/PageLayout";
import {useState} from "react";
import {ItemCounter} from "../../components/icon/ItemCounter";
import {Link, useNavigate} from "react-router-dom";
import {
    AcademicCapIcon,
    ChatBubbleLeftEllipsisIcon,
    ChatBubbleLeftIcon,
    LockClosedIcon,
    LockOpenIcon
} from "@heroicons/react/24/outline";
import StartQuizModal from "./StartQuizModal";
import {useBot} from "../../providers/BotProvider";
import FastModal from "../../components/FastModal";
import {ItemCounterRight} from "../../components/icon/ItemCounterRight";
import {useWebApp} from "@vkruglikov/react-telegram-web-app";


function Home() {

    const navigate = useNavigate()
    const {user, authenticated} = useAuth()
    const {prices, categories, loading} = useBot()
    const [quizModal, setQuizModal] = useState(false)
    const [limitQuizModal, setLimitQuizModal] = useState(false)
    const [postId, setPostId] = useState(null)
    const WebApp = useWebApp();

    const openPost = (item) => {
        setPostId(item.id)

        if (item.id === 92){
            if (user.product1){
                user.can_buy_product1
                    ?navigate(`/category/${item.id}`)
                    :navigate(`/form-request`)
            }else{
                user.can_buy_product1
                    ?navigate(`/payment?showPrice2=true`)
                    :navigate(`/form-request`)
            }

        }else{
            item.can_view === true
                ? navigate(`/category/${item.id}`)
                : navigate(`/payment`)
        }


        // item.can_view === true
        //     ? navigate(`/category/${item.id}`)
        //     : item.level === user.level + 1
        //         ? setQuizModal(true)
        //         : setLimitQuizModal(true)
    }

    if(WebApp.platform === "weba"){
        return (<div>برای تجربه کاربری بهتر لطفا از طریق اپلیکیشن تلگرام وارد شوید</div>)
    }

    return (
        <PageLayout
            back={false}
            loading={loading || !authenticated}
            title="Start Page"
            className="bg-wrapper p-8">

            {quizModal && <StartQuizModal onAction={() => navigate(`/quiz/${postId}`)} setIsPremium={setQuizModal}/>}
            {limitQuizModal  && <FastModal
                onAction={() => setLimitQuizModal(false)}
                onActionTitle="فهمیدم !"
                setClosed={setLimitQuizModal}
                icon={<ChatBubbleLeftEllipsisIcon className="w-14 h-14" />}
                title="عدم دسترسی"
                body="ابتدا باید آزمون مرحله قبل را با موفقیت پاس شوید"
            />}



            <div className="flex flex-col space-y-4 bg-white bg-opacity-10 rounded-md p-4">
                {categories && categories.map((item) => (
                    <div>
                        <button
                            type="button"
                            onClick={() => openPost(item)}
                            className="w-full">
                            <div className="flex flex-row-reverse items-center justify-between">
                                <div className="flex flex-row-reverse items-center text-left gap-2">
                                    <span className="">
                                        <img
                                            className="w-[60px] h-[60px] rounded-md ml-2"
                                            src={item.cover_thumb}
                                            alt={item.title}/>
                                    </span>
                                    <div>
                                        <h3 className="text-lg">{ item.title }</h3>
                                    </div>
                                </div>
                                <div className="flex">
                                    <ItemCounterRight number={ !item.can_view && <LockClosedIcon className="w-4 h-4 inline-block" />} />
                                </div>
                            </div>
                        </button>
                    </div>
                ))}
            </div>



            <div className="grid grid-cols-2 gap-3 my-4">
                {/*<Link to="/form-request" className="card p-2 col-span-2">*/}
                {/*    <div className="flex justify-between items-center">*/}
                {/*        <div>*/}
                {/*            <p className="">درخواست عضویت دوره سنشی</p>*/}
                {/*            <p className="text-xs text-slate-400"></p>*/}
                {/*        </div>*/}
                {/*        <span className="text-2xl">*/}
                {/*            <img className="w-16" src="https://3dicons.sgp1.cdn.digitaloceanspaces.com/v1/dynamic/color/chat-text-dynamic-color.png" alt=""/>*/}
                {/*        </span>*/}
                {/*    </div>*/}
                {/*</Link>*/}

                <Link to="/affiliate" className="card p-2 col-span-1">
                    <div className="flex justify-between items-center">
                        <div>
                            <p className="">رفرال</p>
                            <p className="text-xs text-slate-400">دریافت لینک رفرال</p>
                        </div>
                        <span className="text-2xl">
                            <img className="w-16" src="https://3dicons.sgp1.cdn.digitaloceanspaces.com/v1/dynamic/color/money-dynamic-color.png" alt=""/>
                        </span>
                    </div>
                </Link>
                <Link to="/payment" className="card p-2 col-span-1">
                    <div className="flex justify-between items-center">
                        <div>
                            <p className="">اشتراک</p>
                            <p className="text-xs text-slate-400">{ user?.remainder }</p>
                        </div>
                        <span className="text-2xl">
                            <img className="w-16" src="https://3dicons.sgp1.cdn.digitaloceanspaces.com/v1/dynamic/premium/rocket-dynamic-premium.png" alt=""/>
                        </span>
                    </div>
                </Link>
            </div>

            <div className="text-center w-full">
                <img className="w-32 h-auto my-2 mx-auto" src="/logo.png" alt="logo"/>
            </div>

        </PageLayout>
    );
}

export default Home;
