import {Spinner} from "@nextui-org/react";
import {rendition} from "../services/i18n";

export default function PageLoading() {
    return (
        <div className="w-full h-screen flex flex-col items-center">
            <div className="flex flex-col bg-transparent mt-48">
                <img className="mx-auto w-32 h-auto animate-pulse " src="/logo.png" alt=""/>
                <span className="loader"></span>
            </div>
        </div>
    )


    return (
        <div className="w-full h-screen text-center">

            <Spinner label={rendition("loading")} color="primary" className="mt-20" />
        </div>
    )
}