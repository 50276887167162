import PageLayout from "../../components/PageLayout";
import {
    CurrencyDollarIcon,
} from "@heroicons/react/24/outline";
import {useEffect, useState} from "react";
import {Input, useDisclosure} from "@nextui-org/react";
import useApi from "../../services/useApi";
import TelegramButton from "../../components/ui/TelegramButton";
import {useNavigate} from "react-router-dom";
import validationSchemas from "../../schema/validationSchemas";
import {useBot} from "../../providers/BotProvider";



function Withdrawal() {

    const navigate = useNavigate()
    const [walletAddress, setWalletAddress] = useState()
    const [amount, setAmount] = useState()
    const api = useApi()
    const {setMounted} = useBot()

    const handler = () => {
        api.fetchData("/withdrawal", "POST", {
            wallet_address: walletAddress, amount
        }, validationSchemas.withdrawal)
    }

    useEffect(() => {
        if (api.success){
            setMounted(false)
            navigate("/affiliate")
        }
    }, [api.success])

    return (
        <PageLayout title="Start Page" className=" p-8">

            <div className="text-center p-5">
                <span className="inline-block rounded-full p-2 text-white">
                    <CurrencyDollarIcon className="w-14 h-14" />
                </span>
                <h1 className="text-xl font-bold my-2">برداشت درآمد</h1>
                <div className="mb-4">برای ثبت درخواست برداشت درآمد خود از افیلیت ولت آدرس TRC20 و موجودی مورد نظر خود را وارد نمایید</div>
                <div className="flex flex-col gap-3">

                    <Input
                        errorMessage={api.errors?.wallet_address}
                        value={walletAddress}
                        onValueChange={setWalletAddress}
                        label="ولت آدرس TRC20" />
                    <Input
                        errorMessage={api.errors?.amount }
                        value={amount}
                        onValueChange={setAmount}
                        label="مبلغ" />
                </div>
            </div>

            <TelegramButton
                disabled={api.loading}
                text="ثبت درخواست"
                onClick={handler} />


        </PageLayout>
    );
}

export default Withdrawal;
