import PageLayout from "../../components/PageLayout";
import {useEffect} from "react";
import {Button} from "@nextui-org/react";
import {useNavigate} from "react-router-dom";
import {useHapticFeedback} from "@vkruglikov/react-telegram-web-app";
import confetti from "canvas-confetti";

function PaymentResult() {

    const navigate = useNavigate();
    const [impactOccurred] = useHapticFeedback()

    useEffect(() => {


        impactOccurred('light')
        var end = Date.now() + (3 * 1000);
        var colors = ['#bb0000', '#ffffff'];

        (function frame() {
            confetti({
                particleCount: 2,
                angle: 60,
                spread: 55,
                origin: { x: 0 },
                colors: colors
            });
            confetti({
                particleCount: 2,
                angle: 120,
                spread: 55,
                origin: { x: 1 },
                colors: colors
            });

            if (Date.now() < end) {
                requestAnimationFrame(frame);
            }
        }());

    }, []);

    return (
        <PageLayout
            back={false}
            title="Start Page"
            className="bg-wrapper p-8">


            <div className="card">
                <div className="text-center p-5">
                <span className="inline-block rounded-full p-2 text-white">
                    <span className="text-6xl">✅</span>
                </span>
                    <h1 className="text-xl font-bold my-2">پرداخت موفق</h1>
                    <p>اشتراک شما در ربات با موفقیت فعال شد</p>
                    <Button
                        fullWidth
                        className="mt-10"
                        onClick={() => navigate(`/`)}
                    >🤞 بزن بریم</Button>
                </div>
            </div>

        </PageLayout>
    );
}

export default PaymentResult;
