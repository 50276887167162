import React, {useEffect} from "react";
import {useLocation, useParams} from "react-router-dom";
import useApiGuest from "../../services/useApiGuest";
import {Spinner} from "@nextui-org/react";
import {Toaster} from "react-hot-toast";

function Player() {

    const { id } = useParams()
    const {loading, data, fetchData} = useApiGuest()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const utl = queryParams.get('utl');

    useEffect(() => {
        fetchData(`/player/${id}?utl=${utl}`)
    }, []);

    return (
        <div
            onContextMenu={e => e.preventDefault()}
            className="bg-wrapper-blue text-white p-8 max-w-lg mx-auto">
            <Toaster/>

            <img className="w-32 h-auto mx-auto" src="/logo.png" alt="logo"/>
            
            <div className="p-2">

                { loading && <Spinner color="danger" size="lg" /> }
                
                {data?.attachment && (
                    <video className="w-full mx-auto" preload="none"
                           onContextMenu={e => e.preventDefault()}
                           controls controlsList="nodownload">
                        <source src={data.attachment} type="video/mp4" />
                    </video>
                ) }

                <h3 className="my-4 text-xl font-bold text-center">{data?.title}</h3>
                <p className="text-justify">{data?.message}</p>

                {data && data.message === null &&  data.attachment === null && (
                    <div className="p-3 text-center">محتوای این بخش بزودی قرار میگیرد</div>
                )}
                
            </div>
        </div>
    );
}

export default Player;
