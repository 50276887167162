import {useAuth} from "../../providers/AuthProvider";
import PageLayout from "../../components/PageLayout";
import {useEffect, useMemo, useState} from "react";
import {ItemCounter} from "../../components/icon/ItemCounter";
import {useNavigate, useParams} from "react-router-dom";
import {useBot} from "../../providers/BotProvider";
import FastModal from "../../components/FastModal";
import {Link} from "@nextui-org/react";


function Category() {

    const { id } = useParams()
    const navigate = useNavigate()
    const {user} = useAuth()
    const {categories, loading} = useBot()
    const [category, setCategory] = useState()

    const openPost = (item) => {
        navigate(`/post/${item.id}`)
    }

    useEffect(() => {
        setCategory(categories.filter(item => item.id === parseInt(id))[0])
    }, [])

    console.log(category, id)

    return (
        <PageLayout
            back={true}
            loading={loading}
            title="Start Page"
            className="bg-wrapper p-8">

            <div className="pb-4 text-center">
                <img className="w-36 mx-auto inline-block my-4" src="https://3dicons.sgp1.cdn.digitaloceanspaces.com/v1/dynamic/color/file-text-dynamic-color.png" alt=""/>
                <h1 className="text-2xl font-bold">{category?.title}</h1>
                <p className="">لیست مطالب</p>
            </div>


            { category && category.message && (
                <div className="p-3 text-center">{category.message}</div>
            )}


            { category && category.channel && (
                <Link color="primary"
                      href={category.channel}
                      className="p-3 text-center mx-auto">لینک اختصاصی عضویت در کانال افیلیت</Link>
            )}

            <div className="flex flex-col gap-2">
                {category && category.children && category.children.map((item) => (
                    <div className="flex flex-col space-y-4 bg-white bg-opacity-10 rounded-md p-3">
                        <button
                            type="button"
                            onClick={() => openPost(item)}
                            className="w-full">
                            <div className="flex items-center justify-between">
                                <div className="flex items-start text-right gap-2">
                                <span className="">
                                    {/*<img className="w-[60px] h-[60px] rounded-md ml-2" src="https://picsum.photos/id/${item.id}/100" alt=""/>*/}
                                </span>
                                    <div>
                                        <h3 className="text-lg">{ item.title }</h3>
                                    </div>
                                </div>
                                <div className="flex">
                                    <ItemCounter />
                                </div>
                            </div>
                        </button>
                    </div>
                ))}

                {category && category.children.length === 0 && (
                    <div className="p-3 text-center">محتوای این بخش بزودی قرار میگیرد</div>
                )}
            </div>

        </PageLayout>
    );
}

export default Category;
