import PageLayout from "../../components/PageLayout";
import {
    CurrencyDollarIcon,
} from "@heroicons/react/24/outline";
import React, {useEffect, useState} from "react";
import {Button, Input} from "@nextui-org/react";
import useApi from "../../services/useApi";
import TelegramButton from "../../components/ui/TelegramButton";
import {useNavigate} from "react-router-dom";
import validationSchemas from "../../schema/validationSchemas";
import {useBot} from "../../providers/BotProvider";
import {useAuth} from "../../providers/AuthProvider";



function FormRequestActivate() {

    const navigate = useNavigate()
    const api = useApi()
    const {setMounted} = useBot()
    const {authenticated, user} = useAuth()

    const [code, setCode] = useState(null);

    const handleSubmit =  () => {
        api.fetchData("/form-request-activate", "POST", {code})
    }


    useEffect(() => {
        if(api.success){
            setMounted(false)
            navigate("/payment/result-senshy")
        }
    }, [api.success]);



    return (
        <PageLayout title="Start Page" className=" p-8">
            <div className="text-center p-5">
                <span className="inline-block rounded-full p-2 text-white">
                    <CurrencyDollarIcon className="w-14 h-14" />
                </span>
                <h1 className="text-xl font-bold my-2">کد فعال سازی سنشی</h1>
                <div className="mb-4 text-right my-4">
                    در صورت داشتن کد فعال سازی دوره سنشی در فیلد زیر وارد نمایید تا دسترسی شما به دوره سنشی فعال گردد
                </div>

                <Input
                    label="کد فعال ساز"
                    onValueChange={(v) => setCode(v)} />
                <Button
                    fullWidth
                    isLoading={api.loading}
                    color="primary"
                    className="mt-4"
                    onPress={handleSubmit}>چک کردن کد</Button>
            </div>
        </PageLayout>
    );
}

export default FormRequestActivate;
