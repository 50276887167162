import {
    Modal,
    ModalContent,
    ModalBody,
    ModalFooter,
    useDisclosure
} from "@nextui-org/react";
import {StarIcon, XCircleIcon} from "@heroicons/react/24/solid";
import {rendition} from "../../services/i18n";
import {useEffect} from "react";
import TelegramButton from "../../components/ui/TelegramButton";
import {useNavigate, useSearchParams} from "react-router-dom";
import {
    AcademicCapIcon,
    ArrowTrendingUpIcon,
    RocketLaunchIcon,
    TrophyIcon,
    WalletIcon
} from "@heroicons/react/24/outline";



function Content({commission}) {
    return (
        <>
            <div className="text-center p-5">
                <span className="inline-block rounded-full p-2 text-white">
                    <TrophyIcon className="w-14 h-14" />
                </span>
                <h1 className="text-xl font-bold my-2">افیلیت</h1>
                <p>هر کاربری که با لینک اختصاصی شما وارد ربات شود و خرید انجام دهد {commission}% از خرید کاربر به درآمد شما افزوده می شود</p>
            </div>
        </>
    )
}


const AffiliateModal = ({setIsPremium, onAction, commission}) => {

    const navigate = useNavigate()
    const {isOpen, onOpen} = useDisclosure()
    const [queryParameters] = useSearchParams()
    let bot_id = queryParameters.get("bot_id")

    useEffect(() => {
        onOpen()
    }, []);

    return (
        <Modal
            closeButton={<XCircleIcon />}
            classNames={{
                closeButton: "hover:bg-white/5 active:bg-white/10 inline-block w-10 h-10 m-2",
            }}
            isOpen={isOpen}
            backdrop="blur"
            isDismissable={false}
            onClose={() => setIsPremium(false)}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalBody>
                            <Content commission={commission} />
                        </ModalBody>
                        <ModalFooter>
                            <TelegramButton
                                text="متوجه شدم"
                                onClick={onAction} />
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    )
}

export default AffiliateModal