import {CheckCircleIcon} from "@heroicons/react/24/outline";
import {XCircleIcon} from "@heroicons/react/24/solid";
import {isNull} from "util";



const checkbox = (index, correct_option, answer) => {
    if( answer === index &&  correct_option === answer ){
        return <CheckCircleIcon className="w-8 h-8 inline-block text-green-500" />
    }
    if(answer === index &&  correct_option !== answer){
        return <XCircleIcon className="w-8 h-8 inline-block text-red-500" />
    }else{
        return <span className="w-6 h-6 mt-1 border border-white rounded-full" />
    }
}



function ShowQuestion({canAnswer, questionId, question, selectAnswer}){

    return (
        <>
            <h1 className="my-4">{question.message}</h1>

            {question.options.map((value, index) => (
                <button
                    disabled={!canAnswer}
                    type="button"
                    onClick={() => selectAnswer(questionId, index)}
                    className="flex items-center justify-between">
                    <div className="flex gap-2 items-start text-right">
                        <div className="flex">
                            {checkbox(index, question.correct_option, question.answer)}
                        </div>
                        <div>
                            <h3>{value}</h3>
                        </div>
                    </div>

                </button>
            ))}
        </>
    )
}

export default ShowQuestion;