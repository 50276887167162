import {Button} from "@nextui-org/react";
import QRCode from "react-qr-code";
import {Square2StackIcon} from "@heroicons/react/24/outline";
import toast from "react-hot-toast";


function WalletShow({wallet, amount}) {


    return (
        <div className="flex flex-col space-y-4">

            <div className="">
                <div className="pb-4 text-center">
                    <QRCode
                        size={120}
                        className="w-36 mx-auto mb-4"
                        value={wallet}
                        viewBox={`0 0 256 256`}
                    />
                    <h1 className="text-2xl font-bold">پرداخت با تتر</h1>
                    <p className="my-2">معادل {amount} تتر (USDT - TRC20) را به ولت آدرس زیر واریز نمایید</p>
                </div>
                <p className="text-sm break-all text-center">{wallet}</p>
                <p className="text-sm text-center">پس از واریز حدودا 2 دقیقه منتظر بمانید تا تراکنش شما تایید شود و خرید شما با موفقیت کامل شود</p>

            </div>

            <div className="text-center">
                <Button
                    fullWidth={false}
                    startContent={<Square2StackIcon className="w-4 h-4" /> }
                    onClick={() => {
                        navigator.clipboard.writeText(wallet)
                        toast.success("کپی شد")

                    }}>
                    کپی ولت آدرس
                </Button>
            </div>

        </div>
    );
}

export default WalletShow;
