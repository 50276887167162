import React, {useEffect, useState} from "react";
import {Button, Input, Tab, Tabs, Textarea} from "@nextui-org/react";
import {RadioGroup, Radio, cn} from "@nextui-org/react";
import {useNavigate} from "react-router-dom";
import useApiGuest from "../../services/useApiGuest";
import QRCode from "react-qr-code";
import {ArrowLeftCircleIcon, Square2StackIcon} from "@heroicons/react/24/outline";
import WalletShow from "../../components/WalletShow";
import toast, {Toaster} from "react-hot-toast";
import CounterTime from "../../components/CounterTime";
import moment from "moment-timezone";
import Counter2Time from "../../components/Counter2Time";

const CustomRadio = (props) => {
    const {children, ...otherProps} = props;

    return (
        <Radio
            {...otherProps}
            classNames={{
                base: cn(
                    "inline-flex m-0 bg-content1 hover:bg-content2 items-center justify-between",
                    "flex-row-reverse max-w-[360px] cursor-pointer rounded-lg gap-4 p-4 border-2 border-transparent",
                    "data-[selected=true]:border-warning"
                ),
                description: cn("dark:text-gray-200")
            }}
        >
            {children}
        </Radio>
    );
};

function Landing() {

    const [plan, setPlan] = useState(1)
    const [type, setType] = useState("usdt")
    const [mobile, setMobile] = useState("")
    const [step, setStep] = useState(1)
    const [paymentId, setPaymentId] = useState()
    const [register, setRegister] = useState(true)
    const [historyId, setHistoryId] = useState(null)
    const payApi = useApiGuest()
    const priceApi = useApiGuest()
    const waitListApi = useApiGuest()
    const checkPaymentApi = useApiGuest()
    const navigate = useNavigate()


    const handlePay = () => {
        payApi.fetchData("/easy-payment", "POST", {
            type: type,
            price_id: plan,
            mobile: mobile,
        })
    }


    useEffect(() => {
        if(payApi.data){
            setPaymentId(payApi.data.payment_id)

            if (payApi.data.wallet) {
                setStep(3)
            }

            if (payApi.data.redirect_url) {

                document.forms[0].submit();
                // let newTab = window.open();
                // newTab.location.href = payApi.data.redirect_url;
                // window.open(payApi.data.redirect_url)
            }

        }
    }, [payApi.data])

    useEffect(() => {
        priceApi.fetchData("/prices")
    }, [])

    useEffect(() => {
        if(priceApi.data){
            setRegister(priceApi.data.register)
        }
    }, [priceApi.data])


    useEffect(() => {

        const intervalId = setInterval(() => {
            paymentId && checkPaymentApi.fetchData("/payment", "POST", {
                payment_id: paymentId,
                wallet: payApi.data.wallet,
            })
        }, 15000)

        if (intervalId) setHistoryId(intervalId)

        return () => clearInterval(intervalId);
    }, [paymentId]);



    useEffect(() => {
        if(historyId && step !== 3)  {
            clearInterval(historyId)
            setHistoryId(null)
            setPaymentId(null)
        }
    }, [step])

    useEffect(() => {
        checkPaymentApi.data?.success && navigate('/payment/result-guest?redirect=' + checkPaymentApi.data?.redirect)
    }, [checkPaymentApi.data])










    const [isTimedOut, setIsTimedOut] = useState(false);

    useEffect(() => {

        const endTime = moment.tz('2024/05/03 17:30', 'Asia/Tehran');
        const now = moment.tz('Asia/Tehran');
        const difference = endTime.diff(now);

        if (difference > 0) {
            const timer = setTimeout(() => {
                setIsTimedOut(true);
            }, difference);

            return () => clearTimeout(timer); // Clear the timer on unmount or state change
        } else {
            setIsTimedOut(true);
        }
    }, []);

    return (
        <div className="bg-wrapper-blue text-white p-8 max-w-lg mx-auto">
            <Toaster/>





            {step > 1 && (
                <div className="relative">
                    <Button
                        color="default"
                        className="absolute left-3 top-3"
                        fullWidth={false}
                        isIconOnly={true}
                        startContent={<ArrowLeftCircleIcon className="w-6 h-6" /> }
                        onClick={() => setStep( prevState => prevState - 1)}>

                    </Button>
                </div>

            )}


            {
                step === 1 &&
                (
                    <>
                        <div className="pb-4 text-center">
                            <img className="w-36 mx-auto inline-block mb-4" src="/logo.png" alt="logo"/>
                            <h1 className="text-2xl font-bold">خرید اشتراک ربات دانشگاه سنشی</h1>
                        </div>
                    </>
                )
            }


            {
                step === 2 &&
                (
                    <>
                        <div className="pb-4 text-center">
                            <img className="w-36 mx-auto inline-block mb-4" src="/logo.png" alt="logo"/>
                            <h1 className="text-2xl font-bold">انتخاب مدت اشتراک</h1>
                            <p className="">مدت اشتراک و روش پرداخت مورد نظر خود را انتخاب نمایید</p>
                        </div>
                    </>
                )
            }



            <div className="card">
                <div className="flex w-full flex-col">

                    { register && step === 1 && (
                        <p className="mb-4 text-center">
                            با ثبت نام داخل دانشگاه سنشی شانس برنده شدن سفر دبی و مشاوره گرفتن از استاد و شانس برنده شدن ۲ تا ۲۰۶ و ۱۰ تا ایفون ۱۵ و کلی جایزه خفن دیگه رو دارید
                        </p>
                    )}

                    {/*{ !register && (*/}
                    {/*    <>*/}
                    {/*        <h3 className="p-4 text-lg text-center">ثبت نام دانشگاه سنشی تمام شده برای دونستن فرصت بعدی و اطلاعات بیشتر شمارتون رو وارد کنید</h3>*/}

                    {/*        <Input*/}
                    {/*            label="شماره موبایل"*/}
                    {/*            placeholder="0912****"*/}
                    {/*            className="dir-ltr"*/}
                    {/*            onValueChange={(v) => setMobile(v)} />*/}

                    {/*        <Button*/}
                    {/*            className="mt-4"*/}
                    {/*            fullWidth*/}
                    {/*            isLoading={waitListApi.loading}*/}
                    {/*            color="primary"*/}
                    {/*            onClick={() => {*/}
                    {/*                if (mobile.length < 10){*/}
                    {/*                    toast.error("شماره موبایل نامعتبر")*/}
                    {/*                }else{*/}
                    {/*                    setStep(2)*/}
                    {/*                    waitListApi.fetchData("/wait-list", "POST", {mobile})*/}
                    {/*                }*/}
                    {/*            }}>ثبت</Button>*/}
                    {/*    </>*/}
                    {/*)}*/}

                    {/*{!isTimedOut && register && (*/}
                    {/*    <>*/}
                    {/*        <CounterTime />*/}
                    {/*    </>*/}
                    {/*)}*/}

                    {!isTimedOut && (
                        <>
                            <CounterTime />
                        </>
                    )}

                    {/*{isTimedOut && register &&  step === 1 && (*/}
                    {/*    <>*/}
                    {/*        <p className="text-center my-2">*/}
                    {/*             مانده تا پایان ثبت نام*/}
                    {/*        </p>*/}
                    {/*        <Counter2Time />*/}
                    {/*        <div className="mt-3"></div>*/}
                    {/*        <br/>*/}
                    {/*    </>*/}
                    {/*)}*/}

                    {isTimedOut && register && (
                        <>
                            <div className="flex w-full flex-col">

                                {
                                    step === 1 && (
                                        <>
                                            <Input
                                                label="شماره موبایل"
                                                placeholder="0912****"
                                                className="dir-ltr"
                                                description="حتما شماره موبایل تلگرام خود را وارد کنید. اشتراک ربات روی شماره وارد شده فعال خواهد شد. اگر خارج از ایران هستید شماره خود را کامل به همراه کد کشوری وارد کنید مانند 9711234567"
                                                onValueChange={(v) => setMobile(v)} />

                                            <Button
                                                className="mt-4"
                                                fullWidth
                                                color="primary"
                                                onClick={() => {
                                                    if (mobile.length < 10){
                                                        toast.error("شماره موبایل نامعتبر")
                                                    }else{
                                                        setStep(2)
                                                    }
                                                }}>ادامه</Button>
                                        </>
                                    )
                                }


                                {
                                    step === 2 && (
                                        <>
                                            <Tabs onSelectionChange={(value) => setType(value)} className="mx-auto">
                                                <Tab key="usdt" title="درگاه تتری">
                                                    <p>پرداخت هزینه اشتراک ربات با تتر (TRC20)</p>
                                                </Tab>
                                                <Tab key="rials" title="درگاه بانکی">
                                                    <p>پرداخت هزینه اشتراک ربات با درگاه پرداخت بانکی و به صورت آنلاین</p>
                                                </Tab>
                                            </Tabs>
                                            <RadioGroup defaultValue={plan} color="warning">
                                                {priceApi.data
                                                    && priceApi.data.prices
                                                    && priceApi.data.prices.filter(i => i.type === type).map((p) => (
                                                        <CustomRadio
                                                            onChange={(e) => setPlan(e.target.value)}
                                                            description={p.price}
                                                            value={p.id}>
                                                            {p.title}
                                                        </CustomRadio>
                                                    ))}
                                            </RadioGroup>
                                            <Button
                                                className="mt-4"
                                                fullWidth
                                                isLoading={payApi.loading}
                                                color="primary"
                                                onClick={handlePay}>پرداخت</Button>
                                        </>
                                    )
                                }


                                {
                                    step === 3 && (
                                        <WalletShow
                                            wallet={payApi.data?.wallet}
                                            amount={payApi.data?.amount} />
                                    )
                                }
                            </div>
                        </>
                    )}



                    {step === 1 &&  (
                        <div>
                            <br/>
                            <br/>
                            <h3 className="text-center my-4">
                                دقیقا تو دانشگاه قراره چه چیزایی یاد بگیرید؟
                            </h3>

                            کریپتو:
                            <br/>
                            ۱.مقایسه و مسیر آموزش
                            <br/>
                            ۲.شناخت دقیق و معرفی اجزای چارت
                            <br/>
                            ۳.رفتار مارکت در روند حرکت صعودی یا نزولی
                            <br/>
                            ۴.چگونی ترید+معرفی ابزار ها
                            <br/>
                            ۵.تحلیل تکنیکال
                            <br/>
                            ۶.فیبوناچی
                            <br/>
                            ۷.روانشانسی معامله و مدیریت سرمایه
                            <br/>
                            ۸.استراتژی شخصی
                            <br/>
                            ۹.معرفی سایت های اصلی بررسی کریپتو
                            <br/>
                            ۱۰.توضیح دقیق بلاکچین
                            <br/>
                            ۱۱.ماینر و نحوه کسب درآمد از آن ها
                            <br/>
                            <br/>
                            کریپتو و پشتوانه یا همون پول فیات:
                            <br/>
                            ۱.دیفای یا انور مالی غیر متمرکز چی هستند؟
                            <br/>
                            ۲.قراداد های هوشمند
                            <br/>
                            ۳.الگوریتم های اجماع
                            <br/>
                            ۴.چرا مقیاس پذیری در بلاکچین اهمیت دارد؟
                            <br/>
                            ۵.رمز ارزها برای حل چه مشکلاتی به وجود اومدن؟
                            <br/>
                            <br/>

                            برنامه نویسی web1
                            <br/>
                            ۱. ساختار کلی HTML
                            <br/>
                            ۲. ساختار HTML - تگ body
                            <br/>
                            ۳.ساختار HTML - تگ متنی
                            <br/>
                            ۴.ساختار HTML - تگ a,b
                            <br/>
                            ۵.شروع اولین پروژه
                            <br/>
                            ۶.تگ img
                            <br/>
                            ۷.قرار دادن تصویر آفلاین
                            <br/>
                            ۸.قرار دادن لینک در تگa
                            <br/>
                            ۹.parent children
                            <br/>
                            ۱۰.parent children
                            <br/>
                            ۱۱.ساختار لیست li و ul
                            <br/>
                            ۱۲. ساخت دکمه  button
                            <br/>
                            ۱۳.استفاده از تگ  input
                            <br/>
                            ۱۴.استفاده از تگ  label
                            <br/><br/>

                            برنامه نویسی web2
                            <br/>
                            ۱.تغییر style
                            <br/>
                            ۲.CSS Internal
                            <br/>
                            ۳.CSS External
                            <br/>
                            ۴.کامنت کردن
                            <br/>
                            ۵.اولویت ها در CSS
                            <br/>
                            ۶.بررسی inspect
                            <br/>
                            ۷.سلکتورها
                            <br/>
                            ۸.سلکتورها Class
                            <br/>
                            ۹.سلکتورها Class
                            <br/>
                            ۱۰.سلکتورها Parent
                            <br/>
                            ۱۱.ابعاد Weight,Height
                            <br/>
                            <br/>

                            برنامه نویسی web3
                            <br/>
                            ۱.برنامه نویسی با solidity
                            <br/>
                            ۲.آشنایی با اتریوم
                            <br/>
                            ۳.آشنایی با ماشین مجازی اتریوم (EVM)
                            <br/>
                            ۴.آشنایی با قرارداد های هوشمند
                            <br/>
                            ۵.شبکه های اتریومی
                            <br/>
                            ۶.واحد های اتریوم
                            <br/>
                            <br/>



                            فارکس
                            <br/>
                            ۱.مقدمه
                            <br/>
                            ۲.نماد های معاملاتی
                            <br/>
                            ۳.تحلیل بنیادی
                            <br/>
                            ۴.تحلیل تکنیکال
                            <br/>
                            ۵.نحوه ی انجام معاملات و اندازه گیری سود
                            <br/>
                            ۶.کار با متاتریدر
                            <br/>
                            ۷.اصطلاحات رایج
                            <br/>
                            ۸.مدیریت ریسک
                            <br/>
                            ۹.روانشناسی معاملات
                            <br/>
                            <br/>

                            بورس ایران
                            <br/>
                            ۱.آموزش ثبت نام در بورس
                            <br/>
                            ۲.چه پولی رو سرمایه گذاری کنیم ؟
                            <br/>
                            ۳.چرا بورس ایران ؟
                            <br/>
                            ۴.انواع بورس ها
                            <br/>
                            ۵.انواع ارکان و نهاد های بازار سرمایه
                            <br/>
                            ۶.انواع صندوق ها
                            <br/>
                            ۷.وبسایت های مرتبط
                            <br/>
                            ۸.انواع ریسک و روش های سرمایه گذاری در بورس
                            <br/>
                            ۹.۱ تابلوخوانی
                            <br/>
                            ۹.۲تحلیل بنیادی
                            <br/>
                            ۹.۳ آشنایی با پنل کارگزاری
                            <br/>
                            ۹.۴ تحلیل تکنیکال
                            <br/>
                            ۱۰.استراتژی شخصی و مدیریت سرمایه
                            <br/>
                            ۱۱.روانشناسی بازار
                            <br/>
                            <br/>

                            افلیت مارکتینگ
                            <br/>
                            ۱.شش قسمت آموزش کار با کپ‌کات
                            <br/>
                            ۲.دو قسمت آموزش کار با کانوا
                            <br/>
                            ۳.آموزش کار با beacons.ai برای لینک بایو
                            <br/>
                            ۴.آموزش کپشن نویسی و هشتگ گذاری
                            <br/>
                            <br/>

                            دراپ‌شیپینگ
                            <br/>
                            ۱.معرفی سرفصل ها و خلاصه ارائه دراپ‌شیپینگ
                            <br/>
                            ۲.بررسی مشکلات و بحران‌های کاری جهان
                            <br/>
                            ۳.دراپ‌شیپینگ چیست؟
                            <br/>
                            ۴.دراپ‌شیپر کیست؟
                            <br/>
                            ۵.آیا شغل دراپ‌شیپینگ نوعی فریلنسرینگ است؟
                            <br/>
                            ۶.دراپ شیپینگ‌ چگونه کار میکند؟
                            <br/>
                            ۷.بررسی و معرفی تاریخچه دراپ‌شیپینگ
                            <br/>
                            ۸‌.چگونگی دراپ‌شیپینگ در ایران
                            <br/>
                            ۹.بررسی مثال های دراپ‌شیپینگ در ایران و جهان
                            <br/>
                            ۱۰.مزایای دراپ‌شیپینگ(۷مورد)
                            <br/>
                            ۱۱.معایب دراپ‌شیپینگ(۵ مورد)
                            <br/>
                            ۱۲.چگونه یک کسب و کار مبتنی بر دراپ شیپینگ ایجاد کنیم؟
                            <br/>
                            ۱۳.بررسی آمار دراپ‌شیپینگ در جهان
                            <br/>
                            ۱۴.بررسی ِمار دراپ‌شیپینگ جهان
                            <br/>
                            ۱۵.راه اندازی فروشگاه
                            <br/>
                            ۱۶.انتخاب موضوع
                            <br/>
                            ۱۷.فروشگاه خود را سفارشی‌ کنید
                            <br/>
                            ۱۸.یافتن تامین کنندگان
                            <br/>
                            ۱۹.وارد کردن محصولات
                            <br/>
                            ۲۰.بررسی آینده دراپ‌شیپینگ
                            <br/>
                            ۲۱.بررسی قوانین درمورد دراپ‌شیپینگ
                            <br/>
                            ۲۲.نتیجه گیری
                            <br/>
                            <br/>

                            فریلنسری
                            <br/>
                            ۱.تعریف شغل و علت نیاز به آن
                            <br/>
                            ۲.بررسی شغل و درآمد زایی در قرن بیستم
                            <br/>
                            ۳.معرفی شغل های نوظهور قرن بیست و یکم و دلایل نیاز به آنها
                            <br/>
                            ۴.فریلنسری تهدید یا فرصت برای سازمان‌ها
                            <br/>
                            ۵.فریلنسر کیست؟
                            <br/>
                            ۶.تاریخچه لغت فریلنسر
                            <br/>
                            ۷.فریلنسرینگ چیست؟
                            <br/>
                            ۸.اولین مشاغل فریلنسر در دنیا
                            <br/>
                            ۹.فریلنسر بودن و حقوق و دستمزد
                            <br/>
                            ۱۰.معرفی حوزه های فریلنسرینگ
                            <br/>
                            ۱۱.بررسی آمار حوزه های فعالیت فریلنسرها
                            <br/>
                            ۱۲.فریلنسر همان پیمانکار است؟
                            <br/>
                            ۱۳.آمار فریلنسر های موجود در جهان و ایران
                            <br/>
                            ۱۴.چگونه فریلنسر می‌شویم
                            <br/>
                            ۱۵.آیا فریلنسر شدن نیاز به مربی دارد؟
                            <br/>
                            ۱۶.آیا فریلنسر شدن احتیاج به سرمایه اولیه دارد؟
                            <br/>
                            ۱۷.اهمیت مدیریت برنامه ریزی در فریلنسرینگ
                            <br/>
                            ۱۸.چگونه به عنوان فریلنسر خودمان را معرفی کنیم؟
                            <br/>
                            ۱۹.فریلنسر بودن یا جنگیدن به تنهایی؟
                            <br/>
                            ۲۰.چگونه در فریلنسرینگ کار های بیشتری می‌توانیم انجام دهیم؟
                            <br/>
                            ۲۱.معرفی سایت های برتر داخلی و خارجی برای پروژه های فریلنسرینگ
                            <br/>
                            ۲۲.چگونگی ایجاد شبکه ارتباطی در فریلنسرینگ
                            <br/>
                            ۲۳.توسعه فردی یک فریلنسر
                            <br/>
                            ۲۴.بررسی اجمالی مزایا و معایب فریلنسر بودن
                            <br/>
                            ۲۵.مزایای فریلنسر شدن(۷ مورد)
                            <br/>
                            ۲۶.معایت فریلنسر شدن(۷ مورد)
                            <br/>
                            ۲۷.تاثیر اینترنت و شبکه های مجازی بر فریلنسرینگ جنبه های قانونی
                            <br/>
                            ۲۸.بررسی آینده فریلنسرینگ
                            <br/>
                            ۲۹.نتیجه گیری
                            <br/>
                            <br/>

                            هوش مصنوعی
                            <br/>
                            ۱.نحوه ی ایده گرفتن برای پروژه
                            <br/>
                            ۲.نحوه ی ایده گرفتن برای انجام پروژه
                            <br/>
                            ۳.نحوه عملی کردن ایده ها در پروژه
                            <br/>
                            ۴.نحوه ی ساخت ریپازتوری گیت هاب
                            <br/>

                        </div>

                    )}


                </div>
            </div>





            <form method="GET" action={payApi.data?.redirect_url}>
            </form>




        </div>
    );
}

export default Landing;
