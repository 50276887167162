import PageLayout from "../../components/PageLayout";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
// import Button from "../../components/ui/Button";
import useApi from "../../services/useApi";
import {Link, Button} from "@nextui-org/react";
import {useWebApp} from "@vkruglikov/react-telegram-web-app";
import {QuestionMarkCircleIcon, SignalSlashIcon} from "@heroicons/react/24/outline";

function Post() {

    const { id } = useParams()
    const {loading, data, fetchData} = useApi()
    const WebApp = useWebApp();
    const [show, setShow] = useState(false)

    useEffect(() => {
        fetchData(`/post/${id}`)
    }, []);

    return (
        <PageLayout
            loading={loading}
            back={true}
            title="Start Page"
            className="bg-wrapper-blue p-8">

            <img className="w-32 h-auto mx-auto" src="/logo.png" alt="logo"/>


            <div className="p-2">

                {data?.attachment && data.attachment.endsWith(".mp4")  && (
                    <video className="w-full mx-auto" height="240" controls controlsList="nodownload">
                        <source src={data.attachment} type="video/mp4" />
                    </video>
                ) }

                {data?.attachment && data.attachment.endsWith(".mp3")  && (
                    <>
                        <img className="w-full" src="https://senshy.woasarmy.com/storage/thumb/senshy.PNG" />
                        <audio className="w-full mx-auto" controls controlsList="nodownload">
                            <source src={data.attachment} type="audio/mp3" />
                        </audio>
                    </>

                ) }



                {/*{ data && data.url && (*/}
                {/*    <div className="text-center">*/}
                {/*        <Button className="mx-auto my-2" onPress={() => WebApp.openLink(data.url)}>نمایش تمام صفحه</Button>*/}
                {/*    </div>*/}
                {/*) }*/}

                <h3 className="my-4 text-xl font-bold text-center">{data?.title}</h3>
                <p className="text-justify">{data?.message}</p>

                { data && data.channel_file && (
                    <div className="text-center">
                        <Button
                            fullWidth
                            startContent={<SignalSlashIcon className="w-6 h-6" />}
                            className="mx-auto my-2 mt-8 p-2"
                            onPress={() => {
                                WebApp.openTelegramLink(data.channel_file)
                                setShow(true)
                            }}>
                            در مشاهده ویدیو ها مشکل دارید؟
                        </Button>
                        { show && (
                            <div className="bg-blue-100 text-blue-500 p-2 rounded-lg">
                                اگر خودکار به کانال ویدیو های دوره منتقل نشدید مجددا روی دکمه 👆 بالا کلیک کنید
                            </div>
                        )}

                    </div>
                ) }

                {data && data.message === null &&  data.attachment === null && (
                    <div className="p-3 text-center">محتوای این بخش بزودی قرار میگیرد</div>
                )}


                { data && data.cover && (
                    <div className="flex justify-between items-center gap-1 bg-white bg-opacity-10 rounded-md p-4 my-8">
                        <p>فایل</p>
                        <p><Button
                            size="sm"
                            color="success"
                            onClick={() => WebApp.openLink(data.cover)}>دانلود</Button></p>
                    </div>
                ) }

            </div>
        </PageLayout>
    );
}

export default Post;
