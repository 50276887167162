import {
    Modal,
    ModalContent,
    ModalBody,
    ModalFooter,
    useDisclosure
} from "@nextui-org/react";
import {XCircleIcon} from "@heroicons/react/24/solid";
import {useEffect} from "react";
import Button from "./ui/Button";

const FastModal = ({title, icon, body, setClosed, onAction, onActionTitle}) => {

    const {isOpen, onOpen} = useDisclosure()

    useEffect(() => {
        onOpen()
    }, []);

    return (
        <Modal
            placement="auto"
            closeButton={<XCircleIcon />}
            classNames={{
                closeButton: "hover:bg-white/5 active:bg-white/10 inline-block w-10 h-10 m-2",
            }}
            isOpen={isOpen}
            backdrop="blur"
            isDismissable={false}
            onClose={() => setClosed(false)}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalBody>
                            <div className="text-center p-5">
                                <span className="inline-block rounded-full p-2 text-white">
                                    {icon}
                                </span>
                                <h1 className="text-xl font-bold my-2">{title}</h1>
                                <p>{body}</p>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                fullWidth onClick={onAction} >{onActionTitle}</Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    )
}

export default FastModal