import {useEffect} from "react";
import {useAuth} from "../providers/AuthProvider";
import {useInitData} from "@vkruglikov/react-telegram-web-app";
import useApi from "../services/useApi";
import PageLoading from "../components/PageLoading";

function TelegramAuthenticateLayout({children}) {

    const {data, fetchData} = useApi()
    const {authenticated, login} = useAuth()
    const [initDataUnsafe, initData] = useInitData()

    useEffect( () => {
        fetchData("/login", "POST", {query: initData})
    }, []);

    useEffect(() => {
        if ( !authenticated && data )
            login(data.user, data.token)
    }, [data]);

    return (
        <>
            {authenticated ? children : <PageLoading />}
            {/*{children}*/}
        </>
    );
}

export default TelegramAuthenticateLayout;
