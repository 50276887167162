import PageLayout from "../../components/PageLayout";
import {useEffect, useState} from "react";
import confetti from 'canvas-confetti';
import {useNavigate, useParams} from "react-router-dom";
import Button from "../../components/ui/Button";
import { useHapticFeedback } from '@vkruglikov/react-telegram-web-app';
import useApi from "../../services/useApi";
import QuizQuestion from "./QuizQuestion";
import QuizResult from "./QuizResult";
import {useBot} from "../../providers/BotProvider";


function Quiz() {

    const [canAnswer, setCanAnswer]     = useState(true)
    const [questionId, setQuestionId]   = useState(0)
    const [score, setScore]             = useState(0)
    const [questions, setQuestions] = useState([])
    const [impactOccurred] = useHapticFeedback()
    const {affiliateId, setMounted} = useBot()
    const navigate = useNavigate()

    const { id } = useParams()
    const {loading, data, fetchData} = useApi()
    const postApi = useApi()

    useEffect(() => {
        fetchData(`/quiz/${id}`)
    }, []);

    useEffect(() => {
        data && setQuestions(data)
    }, [data]);

    const updateAnswer = (questionId, selectedAnswer) => {
        setQuestions((prevQuestions) =>
            prevQuestions.map((question, index) =>
                index === questionId ? { ...question, answer: selectedAnswer } : question
            )
        );
    }

    const selectAnswer = (id, answer) => {
        if(questions[questionId].correct_option === answer ){
            setScore(prevState => prevState+1)
            impactOccurred('heavy')
            confetti({
                particleCount: 100,
                spread: 70,
                origin: { y: 0.6 }
            });
        }else{
            impactOccurred('rigid')
        }
        updateAnswer(id, answer)
        setCanAnswer(false)
    }

    const nextQuestion = () => {
        setQuestionId(prevState => prevState+1)
        setCanAnswer(true)
    }


    useEffect(() => {
        questionId !== 0 && questionId === questions.length && submitQuiz()
    }, [questionId])

    useEffect(() => {
        postApi.data  && setMounted(false)
    }, [postApi.data])

    const submitQuiz = () => {
        postApi.fetchData(`/quiz/${id}`, "POST", {
            score
        })
    }


    return (
        <PageLayout
            backRouter="/"
            loading={loading || postApi.loading}
            title="Start Page"
            className="bg-wrapper p-8">

            <div className="flex flex-col space-y-4 card">
                { questions
                    && questions[questionId]
                    &&
                    <>
                        <QuizQuestion
                            canAnswer={canAnswer}
                            questionId={questionId}
                            question={questions[questionId]}
                            selectAnswer={selectAnswer} />
                        <div>
                            <Button
                                fullWidth
                                className="my-4"
                                isLoading={canAnswer}
                                disabled={canAnswer}
                                onClick={() => nextQuestion()}
                            >{ questionId + 1 === questions.length
                                ? "🔖 مشاهده نتیجه"
                                : "➡️ سوال بعدی"}</Button>
                        </div>

                    </>
                }

                { questions
                    && questionId !== 0
                    && questionId === questions.length
                    && <QuizResult
                        onAction={() => postApi.data ? navigate(parseInt(affiliateId) === parseInt(id) ? `/affiliate` : `/category/${id}`) : navigate(`/`)}
                        success={postApi.data} />}

            </div>


        </PageLayout>
    );
}

export default Quiz;
