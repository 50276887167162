import {
    Modal,
    ModalContent,
    ModalBody,
    ModalFooter,
    useDisclosure, Textarea, Input
} from "@nextui-org/react";
import {XCircleIcon} from "@heroicons/react/24/solid";
import {useEffect, useState} from "react";
import TelegramButton from "../../components/ui/TelegramButton";
import {useNavigate, useSearchParams} from "react-router-dom";
import {UsersIcon} from "@heroicons/react/24/outline";
import useApi from "../../services/useApi";



function Content({formData, setFormData}) {
    return (
        <>
            <div className="text-center p-3">
                <span className="inline-block rounded-full p-2 text-white">
                    <UsersIcon className="w-14 h-14" />
                </span>
                <h1 className="text-xl font-bold my-2">ارسال پیام</h1>
                <p>ارسال پیام به کاربران غیر قابل بازگشت می باشد</p>
            </div>
            <div className="flex flex-col space-y-4 px-3">


                <Input
                    value={formData.media_id}
                    onValueChange={(value) => setFormData(prevState => ({...prevState, media_id: value}) )}
                    label="شناسه فایل" description="فایل خود را مستقیم روی ربات آپلود یا فوروارد نمایید و شناسه عددی دریافت شده را وارد کنید" />
                <Textarea
                    value={formData.text}
                    onValueChange={(value) => setFormData(prevState => ({...prevState, text: value}) )}
                    label="متن پیام" />
            </div>
        </>
    )
}


const AdminSendMessageModal = ({setIsPremium, onAction}) => {

    const navigate = useNavigate()
    const {isOpen, onOpen} = useDisclosure()
    const [queryParameters] = useSearchParams()
    let bot_id = queryParameters.get("bot_id")

    const [formData, setFormData] = useState({
        media_id: '',
        text: '',
    })

    const {data, loading, fetchData} = useApi()

    const submit = () => {
        fetchData("/admin/send-message", "POST", formData)
    }


    useEffect(() => {
        onOpen()
    }, [])

    return (
        <Modal
            closeButton={<XCircleIcon />}
            classNames={{
                closeButton: "hover:bg-white/5 active:bg-white/10 inline-block w-10 h-10 m-2",
            }}
            isOpen={isOpen}
            backdrop="blur"
            isDismissable={false}
            onClose={() => setIsPremium(false)}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalBody>
                            <Content
                                formData={formData}
                                setFormData={setFormData} />
                        </ModalBody>
                        <ModalFooter>
                            <TelegramButton
                                disabled={formData.text.length === 0 || loading}
                                text="ارسال کن"
                                onClick={submit} />
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    )
}

export default AdminSendMessageModal