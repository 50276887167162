import PageLayout from "../../components/PageLayout";
import {
    CurrencyDollarIcon,
    RocketLaunchIcon,
    UsersIcon
} from "@heroicons/react/24/outline";
import {useEffect, useState} from "react";
import AffiliateModal from "./AffiliateModal";
import {Button, Input, Link, Spinner} from "@nextui-org/react";
import {Square2StackIcon} from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
import {useBot} from "../../providers/BotProvider";
import {useAuth} from "../../providers/AuthProvider";
import useApi from "../../services/useApi";
import {useNavigate} from "react-router-dom";
import {useWebApp} from "@vkruglikov/react-telegram-web-app";



function Affiliate() {

    const navigate = useNavigate()
    const [help, setHelp] = useState(false)
    const {mounted} = useBot()
    const {user} = useAuth()
    const api = useApi()
    const WebApp = useWebApp();

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
        toast.success("کپی شد")
    }

    useEffect(() => {
        api.fetchData("/withdrawal")
    }, [mounted]);


    const obscureString = (str) => {
        if (str.length < 8) {
            return "String is too short";
        }

        var firstFour = str.substring(0, 4);
        var lastFour = str.substring(str.length - 4);
        var middle = "***";

        return firstFour + middle + lastFour;
    }

    return (
        <PageLayout title="Start Page" className="bg-wrapper p-8">

            { help && <AffiliateModal commission={user?.commission} setIsPremium={setHelp} onAction={() => setHelp(false)} />}


            <div className="py-4 text-center">
                <img className="w-36 mx-auto inline-block mb-4" src="https://3dicons.sgp1.cdn.digitaloceanspaces.com/v1/dynamic/color/target-dynamic-color.png" alt=""/>

                <h1 className="text-2xl font-bold">افیلیت مارکتینگ</h1>
                <button
                    className="text-sm mt-2"
                    type="button"
                    onClick={() => setHelp(true)}>چطور کار میکنه؟</button>
            </div>



            <div className="card mt-10">
                <div className="grid grid-cols-3 py-4">
                    <div>
                        <h3 className="text-xl"><RocketLaunchIcon className="inline w-6 h-6" /> %{user.commission}</h3>
                        <h3 className="text-sm">پورسانت</h3>
                    </div>
                    <div>
                        <h3 className="text-xl"><CurrencyDollarIcon className="inline w-6 h-6" /> {user.credit} تتر</h3>
                        <h3 className="text-sm">درآمد فعلی</h3>
                    </div>
                    <div>
                        <h3 className="text-xl"><UsersIcon className="inline w-6 h-6" /> {user.ref_count} </h3>
                        <h3 className="text-sm">تعداد فروش</h3>
                    </div>
                </div>


                <Input
                    fullWidth
                    label="لینک رفرال شما"
                    className="text-left dir-ltr"
                    endContent={<Square2StackIcon
                        onClick={() => {copyToClipboard(user.affiliate_link)}}
                        className="w-6 h-6 text-default-400 cursor-pointer flex-shrink-0" />}
                    value={user.affiliate_link}
                />


                <br/>

            </div>



            <div className="card w-full mt-10">

                <h1 className="text-xl font-bold">درخواست های تسویه</h1>

                {api.loading && <div className="p-5 text-center"><Spinner className="" color="danger" /></div>}

                { api.data && api.data.length === 0 && <p className="text-center p-5">هیچ درخواستی ثبت نشده است</p> }
                { api.data && api.data.length > 0 && api.data.map( i => (
                    <div className="grid grid-cols-3 py-2">
                        <div>
                            <p className="text-xm">مبلغ</p>
                            <p className="text-sm">USDT {i.amount}</p>
                        </div>
                        <div>
                            <p className="text-xm">ولت</p>
                            <p className="text-sm truncate">
                                <span
                                    onClick={() => WebApp.openLink(`https://tronscan.org/#/address/${i.wallet_address}`)}
                                    className="text-sm text-blue-600 cursor-pointer">
                                    {obscureString(i.wallet_address)}
                                </span>
                            </p>
                        </div>
                        <div>
                            <p className="text-xm">TxID</p>
                            <p className="text-sm">
                                { i.has_id?
                                    <span
                                        onClick={() => WebApp.openLink(`https://tronscan.org/#/transaction/${i.has_id}`)}
                                        className="text-sm text-blue-600 cursor-pointer">
                                        {obscureString(i.has_id)}
                                    </span>
                                    : "در صف واریز"}
                            </p>
                        </div>
                    </div>
                    )
                )}



                <Button
                    fullWidth
                    disabled={user?.credit === 0}
                    variant="flat"
                    color="warning"
                    className="text-sm mt-2"
                    type="button"
                    onClick={() => navigate("/withdrawal")}>برداشت درآمد (حداقل 1 تتر)</Button>
            </div>

        </PageLayout>
    );
}

export default Affiliate;
