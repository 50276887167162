import React from "react";

import moment from "moment-timezone";



function Gettime() {


    return (
        <div className="bg-wrapper-blue text-white p-8 max-w-lg mx-auto">
            {moment.tz('Asia/Tehran').toJSON()}
        </div>
    );
}

export default Gettime;
