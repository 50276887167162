import { useNavigate } from "react-router-dom";
import {BackButton} from "@vkruglikov/react-telegram-web-app";
import PageLoading from "./PageLoading";

function PageLayout({children, title= null, back= true, backRouter = null, loading = false, ...restProps}) {

    const telegram = process.env.REACT_APP_TELEGRAM_PWA
    const navigate = useNavigate();

    return (
        <div {...restProps}>
            { telegram && back && <BackButton onClick={() => navigate(backRouter ?? -1)} />}
            { loading ? <PageLoading /> : children}
        </div>
    )
}

export default PageLayout;