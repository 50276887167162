import {useEffect} from "react";
import confetti from 'canvas-confetti';
import {useNavigate} from "react-router-dom";
import Button from "../../components/ui/Button";
import { useHapticFeedback } from '@vkruglikov/react-telegram-web-app';


function QuizResult({success, onAction}) {

    const [impactOccurred] = useHapticFeedback()

    useEffect(() => {
        if (success){
            impactOccurred('light')
            var end = Date.now() + (3 * 1000);
            var colors = ['#bb0000', '#ffffff'];

            (function frame() {
                confetti({
                    particleCount: 2,
                    angle: 60,
                    spread: 55,
                    origin: { x: 0 },
                    colors: colors
                });
                confetti({
                    particleCount: 2,
                    angle: 120,
                    spread: 55,
                    origin: { x: 1 },
                    colors: colors
                });

                if (Date.now() < end) {
                    requestAnimationFrame(frame);
                }
            }());
        }else{
            impactOccurred('light')
        }

    }, []);

    return (
        <>
            <div className="text-center p-5">
                <span className="inline-block rounded-full p-2 text-white">
                    <span className="text-6xl">{success? "🎉" : "😢"}</span>
                </span>
                <h1 className="text-xl font-bold my-2">{success? "قبولی در آزمون" : "عدم قبولی در آزمون"}</h1>
                <p>{success
                    ? "دسترسی به این بخش برای شما فعال شد و می توانید از محتوای آموزشی این بخش استفاده نمایید"
                    : "نمره شما در آزمون کافی نبود! می توانید مجددا مطالب آموزشی را مطالعه نمایید و تا 15 دقیقه دیگر مجددا در آزمون شرکت کنید"}</p>
                <Button
                    fullWidth
                    className="mt-10"
                    onClick={onAction}
                >ادامه</Button>
            </div>
        </>
    )
}


export default QuizResult;
