import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/main/Home";
import Quiz from "./pages/main/Quiz";
import Affiliate from "./pages/main/Affiliate";
import Payment from "./pages/main/Payment";
import Post from "./pages/main/Post";
import Admin from "./pages/main/Admin";
import PaymentResult from "./pages/main/PaymentResult";
import Category from "./pages/main/Category";
import PageLoading from "./components/PageLoading";
import Landing from "./pages/main/Landing";
import PaymentResultGuest from "./pages/main/PaymentResultGuest";
import Gettime from "./pages/main/Gettime";
import Player from "./pages/main/Player";
import Withdrawal from "./pages/main/Withdrawal";
import FormRequest from "./pages/main/FormRequest";
import FormRequestActivate from "./pages/main/FormRequestActivate";
import PaymentResult2 from "./pages/main/PaymentResult2";


function App() {


    return (
        <BrowserRouter>
            <Routes>
                <Route path="/landing" element={<Landing />} />
                <Route path="/payment/result-guest" element={<PaymentResultGuest />} />
                <Route path="/gettime" element={<Gettime />} />
                <Route path="/player/:id" element={<Player />} />
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="/category/:id" element={<Category />} />
                    <Route path="/post/:id" element={<Post />} />
                    <Route path="/quiz/:id" element={<Quiz />} />
                    <Route path="/payment" element={<Payment />} />
                    <Route path="/payment/result" element={<PaymentResult />} />
                    <Route path="/payment/result-senshy" element={<PaymentResult2 />} />
                    <Route path="/affiliate" element={<Affiliate />} />
                    <Route path="/withdrawal" element={<Withdrawal />} />
                    <Route path="/form-request" element={<FormRequest />} />
                    <Route path="/form-request-activate" element={<FormRequestActivate />} />
                    <Route path="/admin" element={<Admin />} />
                    <Route path="/loading" element={<PageLoading />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;