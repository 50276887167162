import PageLayout from "../../components/PageLayout";
import {ArrowLeftCircleIcon, Square2StackIcon, TrophyIcon} from "@heroicons/react/24/outline";
import {useEffect, useMemo, useState} from "react";
import {Button, Tab, Tabs, Link} from "@nextui-org/react";
import {RadioGroup, Radio, cn} from "@nextui-org/react";
import useApi from "../../services/useApi";
import {useLocation, useNavigate} from "react-router-dom";
import {useBot} from "../../providers/BotProvider";
import {useWebApp} from "@vkruglikov/react-telegram-web-app";
import TelegramButton from "../../components/ui/TelegramButton";
import {useAuth} from "../../providers/AuthProvider";
import QRCode from "react-qr-code";
import WalletShow from "../../components/WalletShow";
import {Link as ReactLink} from "react-router-dom";

const CustomRadio = (props) => {
    const {children, ...otherProps} = props;

    return (
        <Radio
            {...otherProps}
            classNames={{
                base: cn(
                    "inline-flex m-0 bg-content1 hover:bg-content2 items-center justify-between",
                    "flex-row-reverse max-w-[360px] cursor-pointer rounded-lg gap-4 p-4 border-2 border-transparent",
                    "data-[selected=true]:border-warning"
                ),
                description: cn("dark:text-gray-200")
            }}
        >
            {children}
        </Radio>
    );
};

function Payment() {

    const [plan, setPlan] = useState(null)
    const [type, setType] = useState("usdt")
    const [paymentId, setPaymentId] = useState()
    const [step, setStep] = useState("1")
    const [wallet, setWallet] = useState()
    const [redirectUrl, setRedirectUrl] = useState(null)
    const {prices, prices2, senshyPricesTelegram, senshyPricesInstagram,  setMounted} = useBot()
    const {user, setUser} = useAuth()
    const WebApp = useWebApp();
    const navigate = useNavigate()
    const {data, loading, success, fetchData} = useApi()
    const paymentApi = useApi()


    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const showPrice2 = queryParams.get('showPrice2');
    const source = queryParams.get('source');

    const handlePay = () => {
        fetchData("/purchase", "POST", {
            type: type,
            price_id: plan,
        })
    }

    const senshyPrices = useMemo(() => {
        if (source && source === "telegram"){
            return senshyPricesTelegram
        } if (source && source === "instagram"){
            return senshyPricesInstagram
        } else {
            return prices2
        }
    }, [prices2, senshyPricesTelegram, senshyPricesInstagram]);

    useEffect(() => {
        if(data){
            console.log(data)

            setPaymentId(data.payment_id)

            if(data.redirect_url){
                WebApp.openLink(data.redirect_url)
                setRedirectUrl(data.redirect_url)
                setStep("3")
            }

            if(data.wallet){
                setWallet(data.wallet)
                setStep("2")
            }
            // (['ios', 'android'].includes(WebApp.platform)
            //     && data.tg_url)
            //     ? WebApp.openTelegramLink(data.tg_url)
            //     : WebApp.openLink(data.redirect_url)
            // navigate(data.link)
        }
    }, [data])


    useEffect(() => {

        const intervalId = setInterval(() => {
            paymentId && paymentApi.fetchData("/payment", "POST", {
                payment_id: paymentId,
                wallet: wallet
            })
        }, 10000)

        return () => clearInterval(intervalId);
    }, [paymentId]);


    useEffect(() => {
        paymentApi.data?.success && setMounted(false)
        // paymentApi.data?.success && setUser(paymentApi.data.user)
        paymentApi.data?.success && navigate('/payment/result')
    }, [paymentApi.data])


    useEffect(() => {
        if (showPrice2 && user && user.product1){
            navigate('/')
        }
    }, [showPrice2]);

    return (
        <PageLayout
            // backRouter="/"
            // back={user && (user.remainder || user.product1) && !source}
            back={user && user.remainder}
            loading={!prices || !prices2}
            title="Start Page"
            className="bg-wrapper p-8">

            { user && user.affiliate && (
                <div className="bg-gray-800 py-2 px-4 -m-6 -mt-8 mb-6">
                    <ReactLink to="/affiliate">
                        <div className="flex justify-between items-center">
                            <div>
                                <p>دریافت لینک رفرال</p>
                            </div>
                            <span className="text-2xl">
                                <ArrowLeftCircleIcon className="w-6 h-6" />
                            </span>
                        </div>
                    </ReactLink>
                </div>
            ) }



            <div className="pb-4 text-center">
                <img className="w-36 mx-auto inline-block mb-4" src="https://3dicons.sgp1.cdn.digitaloceanspaces.com/v1/dynamic/premium/locker-dynamic-premium.png" alt=""/>
                {
                    ( 1 !== 1 &&  (user && !user.product1) && ( showPrice2 || (user && user.ref_id) ) )
                    ?(<>
                        <h1 className="text-2xl font-bold">خرید دوره سنشی</h1>
                        <p className="">دسترسی به دوره سنشی</p>
                    </>)
                    :(<>
                        <h1 className="text-2xl font-bold">خرید اشتراک ربات</h1>
                        <p className="">دسترسی به امکانات ویژه ربات با خرید اشتراک</p>
                    </>)
                }

            </div>

            <div className="card">
                <div className="flex w-full flex-col">



                    { step === "1" && (
                        <>
                            <Tabs onSelectionChange={(value) => setType(value)} className="mx-auto">
                                <Tab key="usdt" title="درگاه تتری">
                                    <p>پرداخت هزینه اشتراک ربات با تتر (TRC20)</p>
                                </Tab>
                                <Tab key="rials" title="درگاه بانکی">
                                    <p>پرداخت هزینه اشتراک ربات با درگاه پرداخت بانکی و به صورت آنلاین</p>
                                </Tab>
                            </Tabs>
                            <RadioGroup defaultValue={plan} color="warning">
                                { prices && prices.filter(i => i.type === type).map((p) => (
                                    <CustomRadio
                                        onChange={(e) => setPlan(e.target.value)}
                                        description={p.price}
                                        value={p.id}>
                                        {p.title}
                                    </CustomRadio>
                                )) }

                                {/*{ ( (user && !user.product1) && (showPrice2 || (user && user.ref_id && !user.remainder)) ) && senshyPrices && senshyPrices.filter(i => i.type === type).map((p) => (*/}
                                {/*    <CustomRadio*/}
                                {/*        onChange={(e) => setPlan(e.target.value)}*/}
                                {/*        description={p.price}*/}
                                {/*        value={p.id}>*/}
                                {/*        {p.title}*/}
                                {/*    </CustomRadio>*/}
                                {/*))}*/}

                            </RadioGroup>

                            {/*{ showPrice2 && (*/}
                            {/*    <Button*/}
                            {/*        onPress={() => navigate("/form-request-activate")}*/}
                            {/*        variant="solid"*/}
                            {/*        className="mt-4"*/}
                            {/*        fullWidth>*/}
                            {/*        کد فعال سازی دارید؟*/}
                            {/*    </Button>*/}
                            {/*)}*/}

                        </>
                    )}


                    { step === "2" && (
                        <>
                            <WalletShow
                                wallet={data?.wallet}
                                amount={data?.amount} />
                        </>
                    )}



                    { step === "3" && (
                        <div className="text-center">
                            <div className="my-4">در صورت منتقل نشدن به درگاه پرداخت روی دکمه زیر کلیک کنید</div>
                            <br/>
                            <Link
                                color="primary"
                                href={redirectUrl}
                            >انتقال به درگاه پرداخت</Link>
                        </div>
                    )}
                </div>


                { step === "1" && (
                    <>
                        <TelegramButton
                            disabled={loading}
                            onClick={handlePay}
                            text="خرید اشتراک" />
                    </>
                )}


            </div>
        </PageLayout>
    );
}

export default Payment;
